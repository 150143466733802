import { ProductTrackingParams } from '@services/payment_product'
import { trackAmplitudeEvent } from './amplitude'

// https://developers.facebook.com/docs/facebook-pixel/advanced/
const trackFBEvent = (name: string, options: unknown = {}, eventData: unknown = {}): void => {
    window.fbq('track', name, options, eventData)
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const trackGTAGEvent = (name: string, options: unknown = {}): void => {
    window.gtag('event', name, options)
}

const trackPinEvent = (name: string, options: unknown = {}): void => {
    window.pintrk('track', name, options)
}

// https://businesshelp.snapchat.com/s/article/pixel-website-install?language=en_US&_ga=2.267074318.1495059954.1616752033-521277455.1616752033
const trackSnapEvent = (name: string, options: unknown = {}): void => {
    window.snaptr('track', name, options)
}

export const trackAmplitudeAndGTAGEvent = (name: string, options?: { [key: string]: unknown }): void => {
    trackGTAGEvent(name, options)
    trackAmplitudeEvent(name, options)
}

export const trackPageView = (): void => {
    trackFBEvent('PageView')
    trackPinEvent('pagevisit')
    trackSnapEvent('PAGE_VIEW')
}

export const trackEvent = (name: string, options: unknown = {}): void => {
    trackFBEvent(name, options)
    trackGTAGEvent(name, options)
    trackPinEvent(name, options)
}

export const trackLead = (params: { uid: string }): void => {
    trackFBEvent('Lead', {}, { eventID: `Lead_${params.uid}` })
    trackGTAGEvent('generate_lead')
    trackPinEvent('lead')
    trackSnapEvent('SAVE')
    window.ktag('add_email', true)
    window.hj('event', 'lead_created')
}

export const trackAddPaymentInfo = (params: { uid: string }): void => {
    trackFBEvent('AddPaymentInfo', {}, { eventID: `AddPaymentInfo_${params.uid}` })
    trackGTAGEvent('add_payment_info')
    trackSnapEvent('ADD_BILLING')
    window.hj('event', 'payment_started')
}

export const trackInitiateCheckout = (params: { uid: string }): void => {
    trackFBEvent('InitiateCheckout', {}, { eventID: `InitiateCheckout_${params.uid}` })
    trackGTAGEvent('begin_checkout')
    trackSnapEvent('START_CHECKOUT')
    window.ktag('checkout', true)
    window.hj('event', 'checkout_opened')
}

export const trackCompleteRegistration = (params: { uid: string }): void => {
    trackFBEvent('CompleteRegistration', {}, { eventID: `CompleteRegistration_${params.uid}` })
    trackGTAGEvent('sign_up')
    trackPinEvent('Signup')
    trackSnapEvent('SIGN_UP')
    window.hj('event', 'registration_completed')
}

export const trackStartTrial = (
    uid: string,
    {
        provider,
        sku,
        price,
        interval,
        interval_count,
        trial_sku,
        trial_price,
        trial_period_day,
        currency,
    }: ProductTrackingParams,
): void => {
    trackFBEvent('StartTrial', { value: price, currency }, { eventID: `StartTrial_${uid}` })
    trackGTAGEvent('purchase', {
        value: price,
        currency,
        interval,
        interval_count,
        trial_sku,
        trial_period_day,
        trial_price,
        sku,
        provider,
    })
    trackGTAGEvent('conversion', { value: price, currency, send_to: 'AW-747219202/Qr4CCMiokfwBEILSpuQC' })
    trackPinEvent('checkout', { value: price, currency, order_quantity: 1 })
    trackSnapEvent('START_TRIAL', { price, currency })
    window.ktag('purchase', true, price + trial_price)
    window.hj('event', 'purchase_succeeded')
}

export const trackDownload = (params: { uid: string }): void => {
    window.fbq('trackCustom', 'ClickToDownload', {}, { eventID: `ClickToDownload_${params.uid}` })
    trackGTAGEvent('click_to_download')
    trackSnapEvent('OPEN_APP')
    window.hj('event', 'download_clicked')
}
