import 'firebase/firestore'
import { createContext, Dispatch, useContext, useEffect, useReducer } from 'react'
import { setAmplitudeUserProperties } from '../../services/client/amplitude'
import { firebase } from '../../services/client/firebaseClient'
import { User, useAuth } from '../auth'
import { Question } from './question'
import * as Sentry from '@sentry/node'
import { trackAmplitudeAndGTAGEvent } from '@services/client/events'

export interface SurveyConfig {
    milestones: number[]
    questions: Question[]
}

export interface StepperState {
    totalSteps: number
    step: number
    progress: number
    milestoneProgress: string
}

export type Answer = string | string[] | number | null | undefined

export type Answers = {
    [name: string]: Answer
}

export interface SurveyState {
    initialized: boolean
    stepper?: StepperState
    currentQuestion?: Question
    prevQuestion?: Question
    nextQuestion?: Question
    canStepNext?: boolean
    answers: Answers
    user?: User
}

export type Action =
    | { type: 'LOAD_SURVEY'; user: User }
    | { type: 'SURVEY_LOADED'; user: User; answers?: Answers }
    | { type: 'QUESTION_ANSWER_CHANGED'; name: string; value?: Answer }
    | { type: 'QUESTION_COMPLETED' }
    | { type: 'QUESTION_STEP_BACK' }
    | { type: 'SET_QUESTION'; path: string }
    | { type: 'RESET_SURVEY' }
    | { type: 'USER_UPDATED'; user: User }

const getStepSize = (questions, index, variants, prev) => {
    let promo
    if (typeof window !== 'undefined') {
        promo = JSON.parse(window.sessionStorage.getItem('promo'))
    }
    if ((prev && index < 2) || (!prev && index >= questions.length - 2)) {
        return 1
    }
    if (variants) {
        const siblingQuestion = prev ? questions[index - 1].name : questions[index + 1].name
        const newIndex = prev ? index - 1 : index + 1
        if (variants['plan-page']?.value !== 'control' && siblingQuestion === 'your_plan') {
            //If we are skipping the next question - check if the next next question also needs to be skipped
            return 2 + (getStepSize(questions, newIndex, variants, prev) - 1)
        } else if (
            (variants['no-trial-sub']?.value === 'no-trial-sub' || !!promo) &&
            siblingQuestion.includes('trial')
        ) {
            return 2 + (getStepSize(questions, newIndex, variants, prev) - 1)
        }
    }
    return 1
}

const getSiblingQuestion = (questions: Question[], source: string, variants, prev?: boolean): Question | null => {
    const sourceIndex = questions.findIndex(q => q.name === source)
    //Check if should skip next question based on next question name + amplitude variants
    const stepSize = getStepSize(questions, sourceIndex, variants, prev)
    if (prev) {
        return sourceIndex > 0 ? questions[sourceIndex - stepSize] : null
    }
    return sourceIndex < questions.length - stepSize ? questions[sourceIndex + stepSize] : null
}

const validateQuestion = (question: Question, answers: Answers, user: User): boolean => {
    if (!question.isRequired) return true
    if (question.validate) return question.validate(question, answers, user)

    if (question.name === 'gender') {
        const answer = answers[question.name]
        return Array.isArray(answer) && answer.length > 0
    }

    switch (question.type) {
        case 'select': {
            const answer = answers[question.name]
            return Array.isArray(answer) && answer.length > 0
        }
        default:
            return true
    }
}

const computeStepperProgress = (config: SurveyConfig, currentStep: number): StepperState => {
    //Loop from last milestone back to the first
    for (let i = config.milestones.length - 1; i >= 0; i--) {
        //If the current step is a milestone...
        if (currentStep === config.milestones[i]) {
            const milestoneTotalSteps =
                currentStep - config.milestones[i - 1] > 4
                    ? currentStep - config.milestones[i - 1] - (currentStep > 10 ? 2 : 1)
                    : currentStep - config.milestones[i - 1]
            return {
                totalSteps: config.milestones.length - 1,
                step: i,
                progress: 0.0,
                milestoneProgress: `${milestoneTotalSteps}/${milestoneTotalSteps}`,
            }
        }
        if (currentStep > config.milestones[i] || i === 0) {
            const milestoneTotalSteps =
                config.milestones[i + 1] - config.milestones[i] > 4
                    ? config.milestones[i + 1] - config.milestones[i] - (currentStep > 10 ? 2 : 1)
                    : config.milestones[i + 1] - config.milestones[i]
            return {
                totalSteps: config.milestones.length - 1,
                step: i,
                progress:
                    i == config.milestones.length - 1
                        ? 100
                        : (currentStep - config.milestones[i]) / (config.milestones[i + 1] - 1 - config.milestones[i]),
                milestoneProgress: `${
                    currentStep - config.milestones[i] - (currentStep > 10 ? 1 : 0)
                }/${milestoneTotalSteps}`,
            }
        }
    }
    return { totalSteps: config.milestones.length - 1, step: 0, progress: 0, milestoneProgress: '1/4' }
}

const createNextStateWithQuestion = (config: SurveyConfig, state: SurveyState, question: Question): SurveyState => {
    const questionIndex = config.questions.findIndex(q => q.name === question.name)
    const variants = state.user?.amplitudeVariants
    return {
        ...state,
        stepper: computeStepperProgress(config, questionIndex + 1),
        currentQuestion: question,
        prevQuestion: getSiblingQuestion(config.questions, question.name, variants, true),
        nextQuestion: getSiblingQuestion(config.questions, question.name, variants),
        canStepNext: state.user ? validateQuestion(question, state.answers, state.user) : false,
    }
}

const computeAnswer = (question: Question, current: Answer, next: Answer): Answer => {
    if (question.type === 'select') {
        // Check choice group and clear previous selected choices if required
        if (question.choiceGroups && question.choiceGroups.length > 0 && current && (current as string[]).length > 0) {
            const change = ((next ?? []) as string[]).filter(x => !(current as string[]).includes(x))
            if (change.length > 0) {
                const previousGroups = question.choiceGroups.filter(choices =>
                    (current as string[]).every(v => choices.includes(v)),
                )
                let nextGroup = previousGroups.find(choices => change.every(v => choices.includes(v)))
                if (!nextGroup) {
                    // find first matching group as fallback
                    nextGroup = question.choiceGroups.find(choices => change.every(v => choices.includes(v)))
                }
                if (!previousGroups.every(choices => choices.every(c => nextGroup.includes(c)))) {
                    return ((next ?? []) as string[]).filter(v => nextGroup.includes(v))
                }
            }
        }
    }
    return next
}

const computeInitialQuestion = (config: SurveyConfig, answers: Answers, user: User): Question => {
    const firstInvalid = config.questions.findIndex(q => !validateQuestion(q, answers, user))
    if (firstInvalid === -1) {
        return config.questions.find(q => q.path === 'your_program')
    }
    return config.questions[firstInvalid]
}

const reducer = (config: SurveyConfig) => (state: SurveyState, action: Action): SurveyState => {
    switch (action.type) {
        case 'LOAD_SURVEY': {
            return { ...state, user: action.user }
        }
        case 'SURVEY_LOADED': {
            const answers = {
                ...(action.answers ?? {}),
                ...state.answers,
            }
            return createNextStateWithQuestion(
                config,
                {
                    initialized: true,
                    answers,
                    user: action.user,
                },
                computeInitialQuestion(config, answers, action.user),
            )
        }

        case 'QUESTION_ANSWER_CHANGED': {
            const answer =
                action.name === state.currentQuestion?.name
                    ? computeAnswer(state.currentQuestion, state.answers[action.name], action.value)
                    : action.value

            setAmplitudeUserProperties({
                [action.name]: answer,
            })
            return createNextStateWithQuestion(
                config,
                {
                    ...state,
                    answers: {
                        ...state.answers,
                        [action.name]: answer,
                    },
                },
                state.currentQuestion,
            )
        }
        case 'QUESTION_COMPLETED': {
            trackAmplitudeAndGTAGEvent('survey_step_completed', {
                step: state.currentQuestion.path,
            })
            const nextQuestion = getSiblingQuestion(
                config.questions,
                state.currentQuestion.name,
                state.user.amplitudeVariants,
            )
            return createNextStateWithQuestion(config, state, nextQuestion)
        }
        case 'QUESTION_STEP_BACK': {
            const prevQuestion = getSiblingQuestion(
                config.questions,
                state.currentQuestion.name,
                state.user.amplitudeVariants,
                true,
            )
            return createNextStateWithQuestion(config, state, prevQuestion)
        }
        case 'SET_QUESTION': {
            const index = config.questions.findIndex(q => q.path === action.path)
            if (index >= 0) {
                return createNextStateWithQuestion(config, state, config.questions[index])
            }
            break
        }
        case 'USER_UPDATED':
            return { ...state, user: action.user }
        case 'RESET_SURVEY':
            return createNextStateWithQuestion(config, initialState, config.questions[0])
        default:
            throw new Error()
    }
}

export const loadUserSurvey = async (user: User): Promise<Answers> => {
    try {
        const snap = await firebase.firestore().collection('user_web_survey').doc(user.id).get()
        if (snap.exists) {
            const surveyData = snap.data()
            localStorage.setItem('survey', JSON.stringify(surveyData.answers))
        }
        const answers = JSON.parse(localStorage.getItem('survey'))
        return answers ?? {}
    } catch (e) {
        Sentry.captureException(e, {
            extra: {
                user_id: user.id,
            },
        })
        const answers = JSON.parse(localStorage.getItem('survey'))
        return answers ?? {}
    }
}

const initialState: SurveyState = { initialized: false, answers: {} }

const surveyContext = createContext<[SurveyState, Dispatch<Action>]>(null)

export interface SurveyProviderProps {
    config: SurveyConfig
}

export const SurveyProvider: React.FC<SurveyProviderProps> = ({ config, ...props }) => {
    const [state, dispatch] = useReducer(
        reducer(config),
        createNextStateWithQuestion(config, initialState, config.questions[0]),
    )

    useEffect(() => {
        if (state.initialized) {
            localStorage.setItem('survey', JSON.stringify(state.answers))
        }
    }, [state.answers])

    const [user, authInitialized] = useAuth()
    useEffect(() => {
        if (authInitialized && state.initialized) {
            if (user?.id) {
                if (user && (user.email !== state.user?.email || user.name !== state.user?.name)) {
                    dispatch({ type: 'USER_UPDATED', user })
                }
            }
        }
    }, [state.initialized, state.user?.id, authInitialized, user])

    return <surveyContext.Provider value={[state, dispatch]}>{props.children}</surveyContext.Provider>
}

export const useSurvey = (): [SurveyState, Dispatch<Action>] => {
    return useContext(surveyContext)
}

const questionContext = createContext<[Question, Answers, Dispatch<Action>]>(null)

export interface QuestionProviderProps {
    question: Question
    answers: Answers
    dispatch: Dispatch<Action>
}

export const QuestionProvider: React.FC<QuestionProviderProps> = ({ question, answers, dispatch, ...props }) => {
    return <questionContext.Provider value={[question, answers, dispatch]}>{props.children}</questionContext.Provider>
}

export const useQuestion = (): [Question, Answers, Dispatch<Action>] => {
    return useContext(questionContext)
}
