import { firebase } from './firebaseClient'

const initAmplitude = (userId: string): void => {
    firebase.analytics().setUserId(userId)
    const amplitude = require('amplitude-js') // eslint-disable-line @typescript-eslint/no-var-requires
    amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, userId, {
        includeReferrer: true,
        includeUtm: true,
        includeGclid: true,
        includeFbclid: true,
    })
    amplitude.setVersionName('1.10.0')
}

const setAmplitudeUserId = (userId: string): void => {
    const amplitude = require('amplitude-js') // eslint-disable-line @typescript-eslint/no-var-requires
    amplitude.getInstance().setUserId(userId)
}

const setAmplitudeUserProperties = (properties: { [key: string]: unknown }): void => {
    const amplitude = require('amplitude-js') // eslint-disable-line @typescript-eslint/no-var-requires
    amplitude.getInstance().setUserProperties(properties)
}

const trackAmplitudeEvent = (name: string, properties?: { [key: string]: unknown }): void => {
    const amplitude = require('amplitude-js') // eslint-disable-line @typescript-eslint/no-var-requires
    amplitude.getInstance().logEvent(name, properties)
    if (name === 'screen_view') {
        window.ktag(properties['screen_name'] as string, true)
    } else {
        window.ktag(name, true)
    }
}

export { initAmplitude, setAmplitudeUserId, setAmplitudeUserProperties, trackAmplitudeEvent }
